.item {
  flex: 0 0 19.7%;
  transition: transform 300ms ease 100ms;
  margin: 0 2px;
  position: relative;
}
.item img {
  height: 100%;
  width: 100%;
  vertical-align: top;
}
