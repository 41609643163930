.slider {
  display: flex;
  position: relative;
}
.slider__container {
  display: flex;
  padding: 0 55px;
  transition: transform 300ms ease 100ms;
  z-index: 3;
  width: 100%;
  height: 120px;
}
@media (max-width: 768px) {
  .slider__container {
    height: 100px;
  }
}
.slider:not(.slider--open) .item:hover .show-details-button {
  opacity: 1;
}
.slider:not(.slider--open) .item:hover {
  transform: scale(1.5) !important;
}
.slider:not(.slider--open):hover .item {
  transform: translateX(-25%);
}
.slider:not(.slider--open) .item:hover ~ .item {
  transform: translateX(25%);
}
