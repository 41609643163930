.slide-button {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 55px;
  background: rgba(0, 0, 0, 0.5);
  border: 0;
  outline: 0;
  padding: 0;
  margin: 40px 0;
  z-index: 4;
}
.slide-button span {
  width: 25px;
  color: #fff;
  display: block;
  margin: 0 auto;
}
.slide-button--next {
  right: 0;
}
.slide-button--next span {
  transform: rotateZ(-90deg);
}
.slide-button--prev {
  left: 0;
}
.slide-button--prev span {
  transform: rotateZ(90deg);
}
